
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import ItemDownloadDialog from '@/components/ItemDownloadDialog.vue';
import { Item } from '@/store/models/ItemModel';
import { Action } from 'vuex-class';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Confirm } from '@/helpers/AuthHelper';

class Option {
  action: string;
  title: string;
  icon: string;
  isDisabled: boolean;
  isLoading: boolean;
  runAction: (rawItem?: any) => void;
}
@Component({
  components: { DefaultLayout, ItemDownloadDialog }
})
export default class extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
    ItemDownloadDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop() items!: Item[];
  /* Store Actions */
  @Action('PlaceOnHold', { namespace: 'items' })
  placeOnHold: any;
  @Action('DeleteItem', { namespace: 'items' })
  deleteItem: any;
  /* Watchers */
  /* Data */
  quickOptions: Array<Option> = [
    {
      action: 'Place on Hold',
      title: 'Place On Hold',
      icon: 'mdi-pause-circle-outline',
      isDisabled: false,
      isLoading: false,
      runAction: (option: Option) => {
        Confirm(
          () => {
            this.runQuickAction(option);
          },
          'Place on hold',
          'This will place all items you have selected on hold. Would you like to continue this action?',
          'I changed my mind',
          'Place on hold'
        );
      }
    },
    {
      action: 'Download',
      title: 'Download',
      icon: 'mdi-cloud-download',
      isDisabled: false,
      isLoading: false,
      runAction: (option: Option) => {
        this.$refs.ItemDownloadDialog.openDialog();
      }
    },
    {
      action: 'Delete',
      title: 'Delete',
      icon: 'mdi-delete-forever',
      isDisabled: false,
      isLoading: false,
      runAction: (option: Option) => {
        Confirm(
          () => {
            this.runQuickAction(option);
          },
          'Delete Items',
          'This will delete ALL your items including any histories and files associated with this item. Are you sure you would like to continue?',
          'I changed my mind',
          'Delete'
        );
      }
    }
    // {
    //   action: 'Place on Hold',
    //   title: 'Place On Hold',
    //   icon: 'mdi-pause-circle-outline',
    //   isDisabled: false
    // }
  ];
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  isActionDisabled(action: string) {
    switch (action) {
      case 'Place on Hold': {
        return (
          this.items &&
          this.items[0] &&
          this.items.every(i => !i.IsApproved || i.IsStaticItem)
        );
      }
      case 'Download': {
        return this.items.some(i => i.IsStaticItem);
      }
      default: {
        return false;
      }
    }
  }
  async runQuickAction(action: Option) {
    action.isLoading = true;
    try {
      switch (action.action) {
        case 'Download':
          this.$refs.ItemDownloadDialog.openDialog();
          break;
        case 'Place on Hold':
          for (const item of this.items) {
            if (item.IsApproved) await this.placeOnHold(item.Id);
          }
          SetSnackBar('Placed all items on hold successfully');
          break;
        case 'Delete':
          for (const item of this.items) {
            await this.deleteItem(item.Id);
          }
          SetSnackBar('Deleted all selected items successfully');
          break;
      }
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    action.isLoading = false;
    this.$emit('bulkItemActionFinished');
  }
  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
  /* Emmited Functions */
}
